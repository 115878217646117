import ReactDOM from 'react-dom'

// Redux
// https://github.com/rt2zz/redux-persist
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _setup from './setup'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 **/
import './_metronic/assets/sass/style.scss'
import store, { persistor } from "./setup/redux/Store";
import './_metronic/assets/css/react-dual-listbox.css';
import './_metronic/assets/_react-select.css';

import {ToastContainer} from "react-toastify";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_setup.setupAxios(axios, store)

Chart.register(...registerables)

ReactDOM.render(
    <MetronicI18nProvider>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={<div>Chargement ...</div>}>
                <App basename={PUBLIC_URL}/>
            </PersistGate>
        </Provider>
        <ToastContainer theme={"colored"}  />
    </MetronicI18nProvider>,
    document.getElementById('root')
)
