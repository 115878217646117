/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'

import { AsideMenuMainGP } from './AsideMenuMainGP'

const RenderAside = ({currentApp}) => {
 // console.log(currentApp)
 
  switch (currentApp) {
    case "GP":
      return (
        <AsideMenuMainGP />
      )
      break;

    default:
      return (
        <AsideMenuMainGP />
      )
      break;
  }
}

export const AsideMenuMain  = ({currentApp}) => {
  const intl = useIntl()
  return (
    <>
      <RenderAside currentApp={currentApp} />
    </>
  )
}
