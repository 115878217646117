import {FC} from 'react'

const Error500: FC = () => {
  return (
    <>
      <h2 className='text-gray-700'>Erreur 500 :</h2>

      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
        Erreur système
      </h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        Quelque chose s'est mal passé!<br/>Veuillez réessayer plus tard.
      </div>
    </>
  )
}

export {Error500}
