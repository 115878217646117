/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import DashBoardModel from "../../../../../models/gp/DashBoardModel";

type Props = {
    className: string,
    cdi: any,
}

const initStatus = {
    disponible: 0,
    sortie_mission: 0,
    sortie_raccordement: 0,
}

const EquipmentAvailablityStatus: React.FC<Props> = ({className, cdi}) => {

    const [status, setStatus] = useState(initStatus)

    const getStatus = async () => {
        let result = initStatus
        let dataModel = new DashBoardModel()
        const {data} = await dataModel.getComponentStatusEquipmentAvailablity()
        if (data.type === "success") {
            result = {
                disponible: data.result.disponible,
                sortie_mission: data.result.sortie_mission,
                sortie_raccordement: data.result.sortie_raccordement,
            }

        }
        return result
    }

    useEffect(() => {
        let isCancelled = false;
        getStatus().then((response) => {
           if(!isCancelled) setStatus(prevState => response)
        });
        return () => {isCancelled = true}
    }, [cdi])

    return (
        <>
            {/* begin::Col */}
            <div className='col bg-light-info px-6 py-8 rounded-2 mb-7 ms-2 me-2'>
                <div className='d-flex align-items-center'>
                    <KTSVG path='/media/icons/duotone/Tools/Tools.svg'
                           className='svg-icon-3x svg-icon-info d-block my-2 me-2'/>

                    <div className='text-info fw-bold fw-bolder fs-2'>
                        Etat de disponibilité des équipements
                    </div>
                </div>

                <div className='d-flex align-items-center flex-column mt-5'>
                    <div className='d-flex justify-content-between w-100 mt-auto'>
                        <span className='fw-bold fs-6 text-success'>
                            Nombre d'équipement disponible :
                        </span>
                        <span className='fw-bold fs-3 text-success'>
                            {status.disponible}
                        </span>
                    </div>

                    <div className='mx-3 w-100'>
                        <hr className='rounded h-2px'/>
                    </div>

                    <div className='d-flex justify-content-between w-100 mt-auto'>
                        <span className='fw-bold fs-6 text-primary'>
                            Nombre d'équipement sortie pour mission :
                        </span>
                        <span className='fw-bold fs-3 text-primary'>
                            {status.sortie_mission}
                        </span>
                    </div>

                    <div className='mx-3 w-100'>
                        <hr className='rounded h-2px'/>
                    </div>

                    <div className='d-flex justify-content-between w-100 mt-auto'>
                        <span className='fw-bold fs-6 text-info'>
                            Nombre d'équipement sortie pour raccordement :
                        </span>
                        <span className='fw-bold fs-3 text-info'>
                            {status.sortie_raccordement}
                        </span>
                    </div>
                </div>
            </div>
            {/* end::Col */}
        </>
    )
}

export {EquipmentAvailablityStatus}
