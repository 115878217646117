import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const types = {
    SAVE_CURRENT_APP: 'SAVE_CURRENT_APP',
    SAVE_CURRENT_DEPARTMENT: 'SAVE_CURRENT_DEPARTMENT',
    DESTROY_APP_STATE: 'DESTROY_APP_STATE',
    SAVE_CURRENCY: 'SAVE_CURRENCY',
    SAVE_CURRENT_USER_ACCESS: 'SAVE_CURRENT_USER_ACCESS',
    SAVE_CURRENT_USER: 'SAVE_CURRENT_USER',
    SAVE_SETTING_SUB_MODULE: 'SAVE_SETTING_SUB_MODULE',
}

const initAppState = {
    currentApp: undefined,
    cdi: undefined,
    cdiDesign: undefined,
    currency: undefined,
    currentUserAccess: [],
    currentUser: undefined,
    setting: {
        suivi_qualif: 0,
        suivi_verif: 0,
        service_provider: 0
    }
}

export const actions = {
    saveCurrentApp: (currentApp: string) => ({
        type: types.SAVE_CURRENT_APP, 
        payload: currentApp
    }),

    saveCurrency: (currency: any) => ({
        type: types.SAVE_CURRENCY, 
        payload: currency
    }),
    
    saveCurrentDepartment: (dep: any) => ({
        type: types.SAVE_CURRENT_DEPARTMENT,
        payload: dep
    }),

    saveCurrentUserAccess: (current_user_access: []) => ({
        type: types.SAVE_CURRENT_USER_ACCESS,
        payload: current_user_access
    }),

    saveCurrentUser: (current_user: []) => ({
        type: types.SAVE_CURRENT_USER,
        payload: current_user
    }),

    saveSettingSubModule: (sub_module: any) => ({
        type: types.SAVE_SETTING_SUB_MODULE,
        payload: sub_module
    }),

    destroyAppState: () => ({type: types.DESTROY_APP_STATE}),
}

const AppReducer = persistReducer(
    {
        storage,
        key: 'app-sent',
        whitelist: ['currentApp', 'cdi', 'cdiDesign', 'currency']
    }, (state: any = initAppState, action: any) => {
        switch (action.type) {
            case types.SAVE_CURRENT_APP : {
                return {
                    ...state,
                    currentApp: action.payload
                }
            }
            break;

            case types.SAVE_CURRENCY : {

                return {
                    ...state,
                    currency: action.payload
                }
            }
            break;

            case types.SAVE_CURRENT_DEPARTMENT : {
                return {
                    ...state,
                    cdi: action.payload.cdi,
                    cdiDesign: action.payload.cdiDesign
                }
            }
            break;

            case types.SAVE_CURRENT_USER_ACCESS : {
                return {
                    ...state,
                    currentUserAccess: action.payload,
                }
            }
            break;

            case types.SAVE_CURRENT_USER : {
                return {
                    ...state,
                    currentUser: action.payload,
                }
            }
            break;

            case types.SAVE_SETTING_SUB_MODULE : {
                let _setting = state.setting
                _setting.suivi_qualif = action.payload.suivi_qualif
                _setting.suivi_verif = action.payload.suivi_verif
                _setting.service_provider = action.payload.service_provider

                return {
                    ...state,
                    setting: action.payload,
                }
            }
            break;

            case types.DESTROY_APP_STATE : {
                //console.log(initAppState)
                return initAppState
            }
            break;

            default :
                return state
        }
    })

export default AppReducer
