/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'

import {RootState} from "../../setup/redux/Store";
import {MasterLayout} from '../../_metronic/layout/MasterLayout'

import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../auth'

import { ErrorsPage } from '../errors/ErrorsPage';

const Routes: FC = () => {
    const isAuthorized = useSelector((state: RootState) => state.authReducer.user, shallowEqual)
    const currentApp = useSelector((state: RootState) => state.appReducer.currentApp, shallowEqual)

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage/>
                </Route>
            ) : (
                <Redirect from='/auth' to='/'/>
            )}

            <Route path='/error' component={ErrorsPage} />
            <Route path='/logout' component={Logout}/>

            {/* <Route path='/auth/forgot-password' component={ForgotPassword} /> */}

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to='/auth/login'/>
            ) : (
                <MasterLayout>
                    <PrivateRoutes currentApp={currentApp}/>
                </MasterLayout>
            )}
        </Switch>
    )
}

export {Routes}
