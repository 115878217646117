import Model from "./Model";
import * as _helpers from "../_metronic/helpers"

class AccountModel extends Model {

    private prefix = "my-account"

    getWithParams(path:any, params:any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getAll(params: any) {
        let path = this.prefix
        return this.withParams(path, params)
    }

    getMyAccount() {
        return this.get(this.prefix)
    }

    saveAccountPhoto(data: {}) {
        return this.post(this.prefix + `/save-photo`, data)
    }

    saveAccountInfo(data: {}) {
        return this.post(this.prefix + `/save-info`, data)
    }

    saveAccountPwd(data: {}) {
        return this.post(this.prefix + `/save-pwd`, data)
    }

    getMyDepartments(params: any) {
        let path = this.prefix + '/departments'
        return this.withParams(path, params)
    }

    getMyQualifications(params: any) {
        let path = this.prefix + '/qualifications'
        return this.withParams(path, params)
    }

}

export default AccountModel
