/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const InitialDomaineForm: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-5 pb-lg-10'>
        <h2 className='fw-bolder fs-1 text-dark'>
          Ajouter un domaine du département
        </h2>
        <div className='fw-bold fs-6 text-gray-400'>
          Ce domaine que vous allez ajouter sera un domaine du département précédemment ajouté.
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Désignation du domaine</span>
        </label>
        
        <Field name='domaine_design_dom' className='form-control form-control-lg form-control-solid' />
        <div className='fv-plugins-message-container text-danger'>
          <ErrorMessage name='domaine_design_dom' />
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-lg-6'>
          <label className='d-flex align-items-center form-label'>
            <span className=''>Préfix</span>
          </label>

          <Field name='domaine_prefix_dom' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='domaine_prefix_dom' />
          </div>
        </div>

        <div className='col-lg-6'>
          <label className='d-flex align-items-center form-label'>
            <span className=''>N2</span>
            <i className='fas fa-info-circle ms-2 fs-7' style={{cursor: 'pointer'}}
              data-bs-toggle='tooltip' data-bs-placement="top"
              title='N2 : correspond au nombre de mois au terme duquel un personnel qualifié doit à nouveau repasser un test de maintien de qualification.'
            ></i>
          </label>

          <div className="input-group">
            <Field name='domaine_n2' className='form-control form-control-lg form-control-solid' />         
            <span className="input-group-text">{"Mois"}</span>
          </div>
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='domaine_n2' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {InitialDomaineForm}
