/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import {toAbsoluteUrl} from '../../_metronic/helpers'

const ErrorsPage: React.FC = () => {
  const history = useHistory()
  const redirectToDashboard = () => {
    history.push('/')
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/logos/login-bg-img.png')}')`}}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a href='/' className='mb-5 pt-lg-2'>
            <img src={toAbsoluteUrl('/media/logos/logo2.png')} className='h-70px mb-5' alt='Logo'/>
          </a>
          <div className='pt-lg-0'>
            <Switch>
              <Route path='/error/404' exact={true}>
                <Error404 />
              </Route>

              <Route path='/error/500' exact={true}>
                <Error500 />
              </Route>

              <Redirect from='/error' exact={true} to='/error/404' />
              <Redirect to='/error/404' />
            </Switch>

            <div className='text-center'>
              <a onClick={redirectToDashboard} className='btn btn-lg btn-orange fw-bolder'>
                Retourner sur le Tableau de bord
              </a>
            </div>
          </div>
        </div>

        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            {/* begin::Copyright */}
            <div className='text-dark order-2 order-md-1'>
              <span className='text-dark fw-bold me-2'>
                {new Date().getFullYear()} &copy;
              </span>
              <a href={void(0)} className='menu-link text-gray-800 text-hover-primary text-uppercase me-2'>
                Smart Sentino 2.0
              </a>
              <span className="me-2">
                Powered by
              </span>
              <a href='https://www.process-instruments.ma/' target='_blank' rel="noopener noreferrer" className='text-gray-800 text-hover-primary text-uppercase me-2'>
                Process Instruments,
              </a>
              <span className="me-2">
                Tous droits réservés
              </span>
            </div>
            {/* end::Copyright */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {ErrorsPage}
