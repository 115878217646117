/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

import {GeneralConditionsView} from './GeneralConditionsView'

type Props = {
  generalConditionId: any
  modalActions: {
    open: (id: any) => void;
    close: () => void;
  }
  className: string
}

const GeneralConditionsModal: React.FC<Props> = ({generalConditionId, modalActions, className}) => {
  useEffect(() => {}, [generalConditionId])
  
  return (
    <>
      <div className='modal fade' id='general_condition_modal' aria-hidden='true'>
        <div className='modal-dialog mw-900px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div className='btn btn-sm btn-icon btn-active-color-orange' data-bs-dismiss='modal' onClick={() => modalActions.close()}>
                <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1'/>
              </div>
            </div>

            <div className='modal-body scroll-y pt-0 pb-5'>
              <GeneralConditionsView className={className} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {GeneralConditionsModal}
