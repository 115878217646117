import Model from "../Model";
import * as _helpers from "../../_metronic/helpers";

class DashBoardAllDepartmentModel extends Model {

    private prefix = "instruments/dashboard-all-departements"

    getWithParams(path: any, params: any) {
        path = this.prefix + path
        return this.withParams(path, params)
    }

    getComponentCountRaccordementOutOrUpDate() {
        let path = '/component/count/out-or-up-date/raccordement'
        return this.getWithParams(path, {})
    }

    getComponentCountVerificationOutOrUpDate() {
        let path = '/component/count/out-or-up-date/verification'
        return this.getWithParams(path, {})
    }

    getComponentCountQualificationOutOrUpDate() {
        let path = '/component/count/out-or-up-date/qualification'
        return this.getWithParams(path, {})
    }

    getComponentStatusEquipmentAvailablity() {
        let path = '/component/status/equipments/availablity'
        return this.getWithParams(path, {})
    }

    getComponentPrevisionRaccordementCost() {
        let path = '/component/prevision/raccordements/cost'
        return this.getWithParams(path, {})
    }

}

export default DashBoardAllDepartmentModel
