/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import parse from "html-react-parser";

import {CheckAccess, currentTime} from '../../../../Utilities';
import DataTable from "../../../../../services/Table/DataTable";
import {ExportToExcelRaccordementNearOrOutDate} from "../../../../../services/Excel/ExportToExcel";

import DashBoardModel from "../../../../../models/gp/DashBoardModel";

type Props = {
    className: string,
    cdi: any,
}

const RaccordementsBoard: React.FC<Props> = ({className, cdi}) => {
    const [tableReload, setTableReload] = useState(true)

    const columns = [
        {
            dataField: "materiel.design_mat",
            text: "Matériels",
            sort: true,
            search: {},
            formatter: (cellContent: any, row: any, rowIndex: any) => {
                return (<>{row.materiel_design_mat}</>)
            }
        },
        {
            dataField: "materiel.code_mat",
            text: "Code",
            sort: true,
            search: {},
            formatter: (cellContent: any, row: any, rowIndex: any) => {
                return (<>{row.materiel_code_mat}</>)
            }
        },
        {
            dataField: "date_rac_next",
            text: "Date prochaine rac.",
            sort: true,
            search: {},
            formatter: (cellContent: any, row: any, rowIndex: any) => {
                return (<>{parse(row.date_rac_next_span)}</>)
            }
        },
        {
            dataField: "domaine.design_dom",
            text: "Domaines",
            sort: true,
            search: {},
            formatter: (cellContent: any, row: any, rowIndex: any) => {
                return (<>{row.domaine_design_dom}</>)
            }
        },
    ];

    const export1 = () => {
        (async () => {
            await ExportToExcelRaccordementNearOrOutDate();
        })()
    }

    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-2 mb-1'>
                            Tableau des équipements dont le raccordement est proche ou dépassé
                        </span>
                    </h3>
                    <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover">
                        {
                            CheckAccess("dashboard-access") &&
                            <a href={void (0)} onClick={() => export1()} className="btn btn-sm btn-light-success">
                                <i className="fas fa-file-excel"></i>
                                Exporter
                            </a>
                        }
                    </div>
                </div>
                {/* end::Header */}

                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <DataTable
                            tableReload={tableReload}
                            dataModel={new DashBoardModel()}
                            columns={columns}
                            path='/component/lists/raccordements/near-out-date'
                        />
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export {RaccordementsBoard}
