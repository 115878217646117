/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
}

const GeneralConditionsView: React.FC<Props> = ({className}) => {

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 text-center pt-5 pb-5'>
          <h2 className='card-title text-uppercase'>
            <span className='card-label fw-bolder fs-1 mb-1'>
              Contrat d'abonnement et conditions de vente et d'utilisations
            </span>
          </h2>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-3'>
          <h3 className='card-title fs-3'>ENTRE LES SOUSSIGNES,</h3>
          <p className='card-title fs-7'>
            Honamé Tech SARL, au capital de 30 000 MAD, immatriculée au Registre du<br/>
            Commerce de Casablanca sous le N° 441447<br/>
            Ci-après dénommée « HT »,<br/>
            D'une part,
          </p>

          <h3 className='card-title fs-3'>ET</h3>
          <p className='card-title fs-7'>
            Vous-même, votre société<br/>
            Ci-après dénommée «le Client»,<br/>
            D'autre part.<br/>
            Désignées ensemble les « Parties » et individuellement la « Partie ».
          </p>

          <h3 className='card-title fs-3 pt-2'>PREAMBULE</h3>
          <p className='card-title fs-7'>HT, société de service en métrologie et formation, fournit le logiciel suivant : </p>
          <ul className='card-title fs-8'>
            <li>Solution de gestion métrologique de parc d’instruments « Smart Sentino » via l’adresse www.smart-sentino.com et disponible sur application exécutable « Smart Sentino » (ci-après dénommé le « Logiciel »).</li>
          </ul>
          <p className='card-title fs-7'>Le Logiciel est disponible en mode service (Mini Parc, SaaS Grand Parc, Cloud personnalisé) et en mode livrable (Serveur local). </p>
          <p className='card-title fs-7'>HT reste joignable pour toute demande ou réclamation relative au fonctionnement du Logiciel grâce au formulaire de contact figurant sur le site du Logiciel ou, au choix du Client, à l’adresse suivante : <span className='text-primary'>assistance@smart-sentino.com</span>. Le Client, afin d’accéder au Logiciel, reconnaît avoir lu et accepté les présentes conditions.</p>

          <h3 className='card-title fs-3 pt-2'>ARTICLE 1 - OBJET</h3>
          <p className='card-title fs-7'>HT met le Logiciel à disposition du Client, via le site internet <a href='https://www.smart-sentino.com/' target='_blank' rel="noopener noreferrer">www.smart-sentino.com</a> (ci-après le « Site internet ». Le Logiciel est mis à disposition à titre gratuit ou payant selon le choix  du Client. Les spécifications du Logiciel sont décrites sur le Site internet, lesquelles ont été lues et acceptées par le Client.</p>
          
          <h3 className='card-title fs-3 pt-2'>ARTICLE 2 - DUREE</h3>
          <p className='card-title fs-7'>Le présent contrat entre en vigueur à sa date d’acceptation par le Client et est conclu sans limitation de durée. Il pourra y être mis un terme par l’une ou l’autre des Parties selon les modalités de l’article « Résiliation »</p>
  
          <h3 className='card-title fs-3 pt-2'>ARTICLE 3 - OBLIGATIONS DES PARTIES</h3>
          <h4 className='card-title fs-5'>3.1 – Obligations de HT</h4>
          <p className='card-title fs-7'>HT est tenu auprès du Client, au titre d’une obligation de moyen, de maintenir l’accès du Logiciel via le Site internet et l’application associée à partir de tout terminal disposant des spécifications techniques requises afin de faire fonctionner le Logiciel. Lesdites spécifications techniques sont portées à la connaissance du Client sur le Site internet. HT s’engage par ailleurs, au titre d’une obligation de moyen, à assurer la confidentialité des données que le Client transmettra au Logiciel via le Site internet. HT est tenu, au même titre, de conserver l’intégrité des données déposées par le Client sur le Logiciel via le Site internet. Le Client reconnaît que le Logiciel étant exploité sur une plateforme mutualisée pour la solution Mini parc et SaaS Grand parc. A ce titre HT fera ses meilleurs efforts afin de maintenir l’exploitation du serveur à un niveau permettant une utilisation normale du Logiciel par le Client. Toutefois, en cas de surcharge de la plateforme rendant impossible l’utilisation du Logiciel, la responsabilité de HT ne pourrait être engagée.</p>
          <h4 className='card-title fs-5'>3.2 – Obligations du Client</h4>
          <p className='card-title fs-7'>Le Logiciel n’étant accessible que via le Site internet ou local et l’application associée, HT remettra un mot de passe et un identifiant (ci-après les « Identifiants ») au Client. Ces Identifiants sont remis après acceptation du Contrat par le Client. Le Client est seul responsable de toutes conséquences découlant notamment de l’usage qu’il fait des Identifiants, de la perte de ces derniers ou de leur usage par un tiers. La responsabilité de HT ne pourra être engagée de ce fait. Le Client reste seul responsable du choix du terminal se connectant au Logiciel et au Site Internet et reconnaît avoir pris connaissance des spécificités techniques du terminal requises pour faire fonctionner le Logiciel. En cas de dysfonctionnement du terminal, notamment en cas de dysfonctionnement lié directement ou indirectement à l’utilisation du Logiciel, HT ne pourra voir sa responsabilité engagée. Le Client est tenu, de payer les échéances mensuelles dues au titre de l’utilisation du Logiciel.</p>

          <h3 className='card-title fs-3 pt-2'>ARTICLE 4 – PROPRIETE INTELLECTUELLE</h3>
          <p className='card-title fs-7'>HT reconnaît détenir ou avoir obtenu tous les droits nécessaires liés à l’exploitation et à la commercialisation du Logiciel et du Site internet. Tous les éléments composant le Logiciel et le Site internet y compris (non limitativement) les codes sources, codes objets et leur documentation associée, restent la propriété de HT. Le Client ne dispose que d’un droit d’utilisation du Logiciel, uniquement sur le Site internet et uniquement pour la durée du Contrat. Le droit d’utilisation ici concédé est non cessible et non transmissible. Le Client n’acquiert aucun droit sur les matériels, logiciels, données ou documents détenus par HT. HT assurera la défense du Client contre toute action de la part d’un tiers revendiquant un droit de propriété intellectuelle, ou se fondant sur une demande en concurrence déloyale ou en parasitisme, concernant le Logiciel (hors logiciels libres) et utilisé par le Client. Les obligations de HT sont soumises à l’ensemble des conditions suivantes :</p>
          <ul className='card-title fs-8'>
            <li>le Client avisera HT immédiatement et par écrit de l’existence de l’allégation ou réclamation, et communiquera à HT tous les renseignements à sa disposition relatifs à cette allégation ou réclamation,</li>
            <li>HT aura seul la direction de la défense et de toute négociation en vue d’une transaction,</li>
            <li>pour autant, le Client coopèrera totalement avec HT en tout ce qui concerne le règlement de l’allégation ou réclamation,</li>
          </ul>
          <p className='card-title fs-7'>Si l’allégation ou réclamation en contrefaçon ou concurrence déloyale ou parasitisme a été reconnue fondée par les tribunaux ou si HT estime, au vu des éléments objectifs dont il dispose, qu’elle est susceptible de l’être, le Client s’engage à accepter que HT, à son choix :</p>
          <ul className='card-title fs-8'>
            <li>obtienne pour le Client le droit de continuer à utiliser le Logiciel,</li>
            <li>ou remplace le Logiciel par un logiciel ou progiciel équivalent non contrefaisant,</li>
            <li>ou modifie le Logiciel de façon à ce qu’il cesse d’être contrefaisant.</li>
          </ul>
          <p className='card-title fs-7'>Toutefois les dispositions ci-dessus ne s’appliquent pas lorsque l’allégation ou réclamation est relative à une utilisation du Logiciel par le Client non conforme aux conditions du Contrat.</p>

          <h3 className='card-title fs-3 pt-2'>ARTICLE 5 – CONDITIONS FINANCIÈRES</h3>
          <p className='card-title fs-7'><b>Les logiciels développés sur mesure et installés sur le serveur interne du Client :</b> les coûts sont évalués en fonction du temps mis sur le développement et la mise en place du projet et sont  indiqués sur le devis. Les ajouts de nouvelles fonctionnalités demandés ultérieurement feront objet d’un nouveau devis.</p>
          <p className='card-title fs-7'><b>Les logiciels en ligne (SaaS) :</b> compte tenu de l’entretien régulier et des mises à jour effectués sur ces logiciels, ils donnent au Client la possibilité de choisir entre des formules d’abonnement à des coûts fixes indiqués sur la page internet dédiée au Logiciel retrouvable sur le site web de HT et aussi sur la commande validée par le client. Ces prix ne tiennent pas compte des coûts générés chez le Client par l’usage de sa connexion internet.</p>
          <p className='card-title fs-7'>Les frais de création de l’espace du Client en ligne à la signature du contrat sont fixés à 1000 MAD HT (100 euro HT) et son payable une seule fois pour toute la durée du contrat à l’exception de la formule Mini Parc dont les frais sont gratuits.</p>
          <p className='card-title fs-7'>Nos formations pour l’utilisation du Logiciel sont assurées à titre gracieux à tous nos clients.</p>

          <h3 className='card-title fs-3 pt-2'>ARTICLE 6 - RESPONSABILITÉ</h3>
          <h4 className='card-title fs-5'>6.1 Conditions Générales</h4>
          <p className='card-title fs-7'>HT ne pourra en aucun cas être tenu responsable d’éventuels dommages causés au Client ou au tiers résultant :</p>
          <ul className='card-title fs-8'>
            <li>de tous cas de force majeure, fait du Client ou d’un tiers ;</li>
            <li>de perte, d’altération, de divulgation de données du fait du Client ou d’un tiers ;</li>
            <li>de l’intrusion de programme malveillant (non limitativement : virus, malware) ou de spam sur le terminal du Client ou son logiciel de messagerie ;</li>
            <li>d’interruption de l’accès au Logiciel en cas de maintenance du Logiciel et/ou du Site Internet par HT ;</li>
            <li>de la fiabilité des transmissions des données, des temps d’accès, des éventuelles restrictions d’accès sur le réseau Internet ;</li>
            <li>des temps de latence dû au réseau Internet ainsi que de l’utilisation d’Internet faite par le Client ;</li>
            <li>des coûts de connexion et/ou dépassement de forfait de connexion internet subit par le Client du fait de l’utilisation du Logiciel ou du Site Internet.</li>
          </ul>
          <p className='card-title fs-7'>La responsabilité de HT ne pourra être engagée qu’à la condition que le Client rapporte la preuve d’une faute de HT. En pareille hypothèse, le dédommagement du Client ne saurait excéder la moitié des sommes versées par le Client à HT au titre de l’utilisation du Logiciel.</p>
          <h4 className='card-title fs-5'>6.2 Protection des données personnelles</h4>
          <p className='card-title fs-7'>Les Parties s’engagent à respecter l’ensemble des dispositions légales et règlementaires applicables à leur activité, notamment celles issues de la loi n° 78-17 « relative à l’informatique, aux fichiers et aux libertés » (ci-après « Loi Informatique et Libertés »). A ce titre, le Client s'engage à satisfaire à l’ensemble des obligations déclaratives et/ou demandes d’autorisation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), concernant les traitements de données à caractère personnel qu’il constitue et/ou exploite ou fait exploiter conséquemment à l’utilisation du Logiciel, ainsi qu’à respecter toutes les obligations incombant aux responsables de traitement au sens de la Loi Informatique et Libertés. Le Client fait son affaire de toute difficulté qui pourrait survenir du fait du non respect de ces obligations et s’engage à se conformer et à réaliser, tout au long du Contrat, les formalités et/ou démarches nécessaires au respect de la réglementation en vigueur, notamment celles qui seraient rendues nécessaires par l’évolution technique des Prestations. Les Parties rappellent qu’en application des dispositions de l’article 35 de la Loi Informatique et Libertés, les données à caractère personnel ne peuvent faire l'objet d'une opération de traitement de la part d'un sous-traitant, que si celui-ci agit sous l'autorité du responsable du traitement, via une « instruction ». Les Parties conviennent de définir la notion d'« instruction », au sens de l’article 35 précité, comme étant acquise lorsque HT agit dans le cadre de l'exécution du Contrat</p>

          <h3 className='card-title fs-3 pt-2'>ARTICLE 7 - RÉSILIATION</h3>
          <h4 className='card-title fs-5 pt-2'>7.1 Résiliation du fait de HT</h4>
          <p className='card-title fs-7'>Pour les formules à abonnement, HT peut suspendre l’accès au Logiciel ou résilier le Contrat à tout moment sans qu’aucune indemnisation ne soit exigible par le Client, que ce dernier bénéficie de l’Option Premium, Gold ou non. En pareille hypothèse, HT est tenu de rendre les données relevant de la propriété du Client et qui ont été remises par le Client via le Logiciel. La résiliation du contrat est effective après réception par mail du Client de la notification de résiliation envoyée par HT.</p>
          <h4 className='card-title fs-5 pt-2'>7.2 Résiliation du fait du Client</h4>
          <p className='card-title fs-7'>Pour les formules à abonnement, le Client peut résilier le Contrat à tout moment, par simple notification par mail (aucun remboursement ne sera effectué).<br/>Toute résiliation anticipée du Client donnera lieu au paiement de plein droit par le Client à HT de la totalité des mensualités consommées. Toutefois, le Client peut résilier le Contrat pour manquement de HT en envoyant à HT par lettre recommandée un courrier mentionnant le manquement de HT et demandant la résiliation du Contrat en respectant un préavis d’un mois.</p>
          <h4 className='card-title fs-5 pt-2'>7.3 Réversibilité</h4>
          <p className='card-title fs-7'>La résiliation a pour effet : </p>
          <ul className='card-title fs-8'>
            <li>L’invalidation des Identifiants du Client sur le Site internet de HT, rendant au Client l’accès impossible au Logiciel</li>
            <li>La mise à disposition par HT en téléchargement de toutes données propriétés du Client et déposées via le Logiciel.</li>
          </ul>

          <h3 className='card-title fs-3 pt-2'>ARTICLE 8 - CONFIDENTIALITE</h3>
          <p className='card-title fs-7'>Les Parties s’engagent au plus strict respect du secret des affaires en ce qui concerne toute information transmise par l’autre Partie et dont elles ont pris connaissance, à l’occasion de l’exécution du Contrat. Les documents ou renseignements confiés par le Client, ainsi que les dossiers élaborés par HT, à l'aide de ces documents et renseignements, sont couverts par le secret des affaires. Toutefois, ne seront pas considérés comme confidentiels les informations, documents ou outils qui :</p>
          <ul className='card-title fs-8'>
            <li>étaient du domaine public au moment de leur divulgation ou sont tombés dans le domaine public sans qu'il y ait eu contravention aux présentes dispositions</li>
            <li>résultent de connaissances internes à l'une ou l'autre des Parties sans qu'il y ait eu violation par elle de la présente obligation de confidentialité</li>
            <li>ont été reçus de manière licite, par l'une ou l'autre des Parties, de tiers, sans obligation de confidentialité.</li>
          </ul>

          <h3 className='card-title fs-3 pt-2'>ARTICLE 9 - DISPOSITIONS GENERALES</h3>
          <p className='card-title fs-7'>Chacune des Parties conservera à sa charge l'ensemble des frais et autres débours engagés par elle au titre des présentes, et au titre des opérations qui y sont stipulées. Les présentes ne pourront être modifiées par un avenant écrit et signé entre les Parties aux présentes. Les termes des présentes prévaudront sur toute stipulation contraire que comporterait tout autre document technique ou commercial, échangé entre les Parties, sauf accord exprès contraire des Parties. Le fait pour une Partie de tolérer un manquement quelconque de l'autre Partie dans l'exécution de ses obligations aux termes des présentes ne devra en aucun cas être interprété comme une renonciation tacite au bénéfice de ses autres obligations.</p>
          
          <h3 className='card-title fs-3 pt-2'>ARTICLE 10 - DROIT APPLICABLE ET TRIBUNAL COMPETENT</h3>
          <p className='card-title fs-7'>Le droit applicable à la présente lettre est le droit Marocain. Sauf dans les cas d’urgence justifiant le recours au juge des référés, les Parties s’engagent, en cas de différend survenant dans le cadre de l’exécution du présent accord, à mettre en œuvre une procédure destinée à faciliter un règlement amiable le plus rapidement possible, avant de saisir le juge compétent. Si dans un délai de quinze (15) jours suivant la date de cette réunion aucune solution n’est trouvée, entérinée par un écrit signé des deux Parties, chaque Partie reprendra sa liberté d’action.</p>
          
          <p className='card-title fs-7 pt-2'>TOUTES DIFFICULTES RELATIVES A L'APPLICATION DE LA PRESENTE LETTRE SERONT SOUMISES, A DEFAUT D'ACCORD AMIABLE SELON LA PROCEDURE AMIABLE CI-DESSUS, AU TRIBUNAL DE COMMERCE DE CASABLANCA A QUI EST DONNEE COMPETENCE TERRITORIALE ET CECI MEME EN CAS DE REFERE, D’APPEL EN GARANTIE OU DE PLURALITE DE DEFENDEURS.</p>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {GeneralConditionsView}
