/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup/redux/Store";
import {PageTitle} from '../../../_metronic/layout/core'
import {CheckAccess} from '../../Utilities';

import {StatusView} from "./components/status/StatusView";
import {RaccordementsBoard} from "./components/lists/RaccordementsBoard";
import {QualificationsBoard} from "./components/lists/QualificationsBoard";

import {StatusAllDepartmentView} from "./components/status/all_department/StatusAllDepartmentView"

type Props = {
  cdi : any
}

const DashboardView: FC<Props> = ({cdi}) => (
  <>
    {/* begin::Header */}
    {
      CheckAccess('dashboard-global-access') &&
      <div className='card-header'>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item text-center'>
              <a className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder fs-3 text-uppercase px-4 ms-1 me-1'
                data-bs-toggle='tab'
                href='#tab_dashboard_by_department'
              >
                Tableau de bord par département actif
              </a>
            </li>

            <li className='nav-item text-center'>
              <a className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder fs-3 text-uppercase px-4 ms-1 me-1'
                data-bs-toggle='tab'
                href='#tab_dashboard_all_department'
              >
                Tableau de bord pour tous les départements
              </a>
            </li>
          </ul>
        </div>
      </div>
    }
    {/* end::Header */}

    {/* begin::Body */}
    <div className='card-body py-3'>
      <div className='tab-content'>
        {/* begin::Tap pane */}
        <div className='tab-pane fade show active' id='tab_dashboard_by_department'>
          {/* begin::Row */}
          <div className='row gy-5 gx-xl-12'>
            <div className='col-xxl-12'>
              <StatusView cdi={cdi} className='card-xl-stretch mb-xxl-8' bgHeaderColor={'orange'}/>
            </div>
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row gy-5 gx-xl-12'>
            {
              CheckAccess('dashboard-access') &&
              <div className='col-xxl-12'>
                <RaccordementsBoard cdi={cdi} className='card-xxl-stretch mb-5 mb-xxl-8' />
              </div>
            }

            {
              CheckAccess('dashboard-access', 'suivi_qualif') &&
              <div className='col-xxl-12'>
                <QualificationsBoard cdi={cdi} className='card-xxl-stretch mb-5 mb-xxl-8' />
              </div>
            }
          </div>
        </div>
        {/* end::Tap pane */}

        {/* begin::Tap pane */}
        {
          CheckAccess('dashboard-global-access') &&
          <div className='tab-pane fade' id='tab_dashboard_all_department'>
            {/* begin::Row */}
            <div className='row gy-5 gx-xl-12'>
              <div className='col-xxl-12'>
                <StatusAllDepartmentView className='card-xl-stretch mb-xxl-8' />
              </div>
            </div>
            {/* end::Row */}
          </div>
        }
        {/* end::Tap pane */}
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardInstrument: FC = () => {
  const cdi = useSelector((state:RootState) => state.appReducer.cdi, shallowEqual)

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Tableau de bord
      </PageTitle>
      
      <DashboardView cdi={cdi} />
    </>
  )
}

export {DashboardInstrument}
