import * as Yup from 'yup'

export interface ICreateAccount {
  person_nom: string
  person_fonction: string
  person_login: string
  person_password: string
  person_password_confirm: string
  person_email: string
  person_phone: string
  society_social_reason: string
  society_country_id: string
  society_city: string
  society_phone: string
  society_email: string
  society_logo: string
  department_design_dep: string
  department_person_titre: string
  domaine_design_dom: string
  domaine_prefix_dom: string
  domaine_n2: string
}

const createInitialSchemas = [
  Yup.object({
    person_nom: Yup.string().required('Ce champ est requis !'),
    person_fonction: Yup.string().required('Ce champ est requis !'),
    person_login: Yup.string().required('Ce champ est requis !').min(4, '4 Caractère au minimum !').max(15, '15 Caractère au maximum !'),
    person_password: Yup.string().required('Ce champ est requis !').min(6, '6 Caractère au minimum !').max(25, '25 Caractère au maximum !'),
    person_password_confirm: Yup.string().required('Ce champ est requis !').min(6, '6 Caractère au minimum !').max(25, '25 Caractère au maximum !').oneOf([Yup.ref('person_password'), null], 'Les mots de passe doivent correspondre !'),
    person_email: Yup.string().required('Ce champ est requis !').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+$/, 'Adresse e-mail non valide !'),
    person_phone: Yup.string().nullable().min(7, '7 chiffres au minimum !').max(20, '20 chiffres au maximum !').matches(/^[+0-9]+$/, "Ce champ ne respecte pas le format d'un numéro de téléphone !"),
  }),
  Yup.object({
    society_social_reason: Yup.string().required('Ce champ est requis !'),
    society_country_id: Yup.string().required('Ce champ est requis !'),
    society_city: Yup.string().required('Ce champ est requis !'),
    society_phone: Yup.string().nullable(),
    society_email: Yup.string().required('Ce champ est requis !').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+$/, 'Adresse e-mail non valide !'),
    society_logo: Yup.string().nullable(),
  }),
  Yup.object({
    department_design_dep: Yup.string().required('Ce champ est requis !'),
    department_person_titre: Yup.string().required('Ce champ est requis !'),
  }),
  Yup.object({
    domaine_design_dom: Yup.string().required('Ce champ est requis !'),
    domaine_prefix_dom: Yup.string().nullable(),
    domaine_n2: Yup.string().nullable().matches(/^[0-9]+$/, 'Ce champ accepte que les nombres entiers'),
  }),
]

const inits: ICreateAccount = {
  person_nom: '',
  person_fonction: '',
  person_login: '',
  person_password: '',
  person_password_confirm: '',
  person_email: '',
  person_phone: '',
  society_social_reason: '',
  society_country_id: '',
  society_city: '',
  society_phone: '',
  society_email: '',
  society_logo: '',
  department_design_dep: '',
  department_person_titre: '',
  domaine_design_dom: '',
  domaine_prefix_dom: '',
  domaine_n2: '',
}

export {createInitialSchemas, inits}
