/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const InitialPersonForm: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-5 pb-lg-10'>
        <h2 className='fw-bolder fs-1 text-dark'>
          Création d'un compte admin
        </h2>
        <div className='fw-bold fs-6 text-gray-400'>
          Renseigner les informations du compte administrateur
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Nom & prénoms</span>
        </label>
        
        <Field name='person_nom' className='form-control form-control-lg form-control-solid' />
        <div className='fv-plugins-message-container text-danger'>
          <ErrorMessage name='person_fonction' />
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-lg-6'>
          <label className='d-flex align-items-center form-label'>
            <span className='required'>Fonction</span>
          </label>

          <Field name='person_fonction' placeholder='ex : Responsable métrologue' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='person_fonction' />
          </div>
        </div>

        <div className='col-lg-6'>
          <label className='d-flex align-items-center form-label'>
            <span className='required'>Adresse e-mail</span>
          </label>

          <Field type='email' name='person_email' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='person_email' />
          </div>
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-lg-6'>
          <label className='d-flex align-items-center form-label'>
            <span className=''>N° de téléphone</span>
          </label>

          <Field name='person_phone' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='person_phone' />
          </div>
        </div>

        <div className='col-lg-6'>
          <label className='d-flex align-items-center form-label'>
            <span className='required'>Nom d'utilisateur (Identifiant)</span>
          </label>

          <Field name='person_login' placeholder='ex : admin' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='person_login' />
          </div>
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-lg-6'>
          <label className='d-flex align-items-center form-label'>
            <span className='required'>Mot de passe</span>
          </label>

          <Field type='password' name='person_password' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='person_password' />
          </div>
        </div>

        <div className='col-lg-6'>
          <label className='d-flex align-items-center form-label'>
            <span className='required'>Confirmer le mot de passe</span>
          </label>

          <Field type='password' name='person_password_confirm' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='person_password_confirm' />
          </div>
        </div>
      </div>
      
    </div>
  )
}

export {InitialPersonForm}
