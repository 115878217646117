import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'

import { GeneralConditions } from './components/GeneralConditions'

const GeneralConditionPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Condition générale d'utilisation
      </PageTitle>
      <GeneralConditions />
    </>
  )
}

export {GeneralConditionPage}