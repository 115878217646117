/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const InitialDepartmentForm: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-5 pb-lg-10'>
        <h2 className='fw-bolder fs-1 text-dark'>
          Ajouter un prémier département
        </h2>
        <div className='fw-bold fs-6 text-gray-400'>
          Pour commencer ajoutez un département (de préférence un département auquel vous appartenez), vous pouvez en ajouter d'autres une fois connecté.
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-lg-8'>
          <label className='d-flex align-items-center form-label'>
            <span className='required'>Désignation du département</span>
          </label>
          
          <Field name='department_design_dep'placeholder='ex : Métrologie' className='form-control form-control-lg form-control-solid' />
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='department_design_dep' />
          </div>
        </div>

        <div className='col-lg-4'>
          <label className='d-flex align-items-center form-label'>
            <span className='required'>Titre</span>
          </label>
          
          <Field as='select' name='department_person_titre' className='form-select form-select-lg form-select-solid'>
            <option value=''>-- Sélectionnez le titre --</option>
            <option value='membre'>Membre</option>
            <option value='chef'>Chef de département</option>
          </Field>
          <div className='fv-plugins-message-container text-danger'>
            <ErrorMessage name='department_person_titre' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {InitialDepartmentForm}
