import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'

import {actions as authActions} from "./AuthRedux";
import {actions as appActions} from "../../setup/redux/AppRedux"

import AuthModel from "../../models/AuthModel";

export function Logout() {
    const dispatch = useDispatch()

    const logout = () => {
        const log = new AuthModel();
        log.logout().then(async (response)  => {
            await dispatch(appActions.destroyAppState())
            await dispatch(authActions.logoutSuccess())
        })
    }

    useEffect(() => {
        logout()
    }, [])

    return (
        <Switch>
            <Redirect to='/auth/login' />
        </Switch>
    )
}
