/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const InitialCompleted: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-5 pb-lg-10'>
        <h2 className='fw-bolder fs-1 text-dark'>
          Vous avez terminé !
        </h2>
        <div className='fw-bold fs-6 text-gray-400'>
          Vous pouvez à présent enregistrer les informations que vous avez fournies
        </div>
      </div>

      <div className='mb-0'>
        <div className='fs-6 text-gray-600 mb-5'>
          Smart Sentino,<br/>
          La solution pour simplifier la gestion de votre parc d'instruments de mesure.<br/>
          Conforme à des référentiels internationaux (ISO, IATF, ...)
        </div>

        {/* <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
          <KTSVG path='/media/icons/duotone/Code/Warning-1-circle.svg' className='svg-icon-2tx svg-icon-warning me-4'/>
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>
                A votre attention !
              </h4>
              <div className='fs-6 text-gray-600'>
                Veillez, vous assurer d'avoir renseigné un email valide, car vos indentifiants de connexion vous seront envoyer par mail.
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export {InitialCompleted}
