/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import DashBoardModel from "../../../../../models/gp/DashBoardModel";

type Props = {
  className: string,
  cdi : any,
}

const initStatus = {
  update: 0,
  update_percent: 0,
  not_update: 0,
  not_update_percent: 0,
  total: 1
}

const CountQualificationOutOrUpDate: React.FC<Props> = ({className,cdi}) => {

  const [status, setStatus] = useState(initStatus)

  const getStatus = async () => {
    let dataModel = new DashBoardModel()
    const {data} = await dataModel.getComponentCountQualificationOutOrUpDate()
    //console.log(data);
    if (data.type === "success") {
      setStatus(prevState => {
        return {
          update: data.result.update,
          update_percent: data.result.update_percent,
          not_update: data.result.not_update,
          not_update_percent: data.result.not_update_percent,
          total: data.result.total,
        }
      })
    } else {
      setStatus(initStatus)
    }
  }

  useEffect(() => {
    getStatus();
  }, [cdi])

  return (
    <>
      {/* begin::Col */}
      <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7 ms-2 me-2'>
        <div className='d-flex align-items-center'>
          <KTSVG path='/media/icons/duotone/General/Shield-check.svg'
            className='svg-icon-3x svg-icon-primary d-block my-2 me-2'/>

          <div className='text-primary fw-bold fw-bolder fs-2'>
            Etat de qualifications du personnel
          </div>
        </div>

        <div className='d-flex align-items-center flex-column mt-5'>
          <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
            <span className='fw-bold fs-6 text-success'>
              Nombre de personnes dont la date de qualification est à jour :
            </span>
            <span className='fw-bold fs-3 text-success'>
              {status.update}
            </span>
          </div>

          <div className='mx-3 w-100'>
            <hr className='rounded h-2px'/>
          </div>

          <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
            <span className='fw-bold fs-6 text-danger'>
              Nombre de personnes dont la date de qualification est en retard :
            </span>
            <span className='fw-bold fs-3 text-danger'>
              {status.not_update}
            </span>
          </div>
        </div>
      </div>
      {/* end::Col */}
    </>
  )
}

export {CountQualificationOutOrUpDate}
