import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'

import { DataProtections } from './components/DataProtections'

const DataProtectionPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Protection des données
      </PageTitle>
      <DataProtections />
    </>
  )
}

export {DataProtectionPage}